(function ($) {
    $(document).ready(function () {
        $(".input_model").on("click", function () {

            //css
            var li = $(this).parent().parent();
            var div = $(li).parent().parent();
            $(div).find("label").css("font-weight", "normal");
            $(div).find(".input_type").css("display", "none");
            $(div).find("button").css("display", "none");
            $(this).parent().css("font-weight", "bold");
            $(li).find(".input_type").css("display", "block");
            $(li).find("button").css("display", "block");
            $("#active_li").attr("id", "");
            $(li).attr("id", "active_li");

            //fill form
            var l = $(this).data("l");
            var h = $(this).data("h");
            var l1 = $(this).data("l1");
            var h1 = $(this).data("h1");
            var d = $(this).data("diametre");
            var dev = $(this).data("developpe");
            var nb_pan = $(this).data("nb_pan");
            var glassform = $(this).data("glassform");
            var glasstype = $(this).data("glasstype");
            var epaisseur = $(this).data("epaisseur");

            $("#l").val(l);
            $("#l1").val(l1);
            $("#h").val(h);
            $("#h1").val(h1);
            $("#d").val(d);
            $("#nb_pan").val(nb_pan);
            $("#glasstype").val(glasstype);
            $("#glassform").val(glassform);
            $("#epaisseur").val(epaisseur);
            $("#glassmodel").val("standard");

            if ($(this).data("glassform") == 'specialcurve') {
                $("#l").val(dev);
            }
        });

        $(".inputTypeVitre").on("click", function () {
            var glassmodel = $(this).val();
            $("#glassmodel").val(glassmodel);
        });

    });


    $(function () {
        if($("#manufacturer").length > 0) {
            $('input[type=radio].input_model').click(function () {
                razPriceEstimate();
                glassform = $('#glassform').val();
                if ($(this).data("epaisseur") != 4 || $(this).data("glassform") != "flat") {
                    if (glassform == "prism") {
                        if ($("#nb_pan").val() == 1)
                            glassform += 2;

                        if ($("#nb_pan").val() == 2)
                            glassform += 3;
                    }
                    $('#glassprismform').val(glassform);
                    launchCalc(glassform);
                }
                launchCalc(glassform);
            });

            $('input[type=radio].calculate-price').click(function () {
                razPriceEstimate();
                glassform = $('#glassform').val();
                launchCalc(glassform);
            });

            // $('input[type=radio].know').on('click', function () {
            //     switchDisplayForm()
            // }).trigger('click');
            $('.glasstype input[type=radio]').on('click', function () {
                if ($(this).val() == 'normalthickness')
                    document.location.href = '/';
                else
                    document.location.href = formActionEstimate + '?glasstype=' + $(this).val();
            });
        }
    });

    var price = 0.0;
    var price_wt = 0.0;

    var inputForm = {
        specialthickness: ['h', 'l'],
        formcurved: ['h', 'h1', 'l'],
        formcupcircle: ['h', 'l'],
        formhalfmoon: ['h', 'l'],
        formroundedcurved1: ['h', 'h1', 'l'],
        formroundedcurved2: ['h', 'h1', 'l', 'l1', 'r'],
        form2sectionscut: ['h', 'h1', 'l', 'l1'],
        form1sectioncut: ['h', 'h1', 'l', 'l1'],
        formtrapeze: ['h', 'l', 'l1'],
        formtriangle: ['h', 'l', 'l1'],
        form1sectionsimple: ['h', 'h1', 'l'],
        form4sections: ['h', 'h1', 'l', 'l1'],
        formoval: ['d', 'd1'],
        formtrianglerect: ['h', 'l', 'l1'],
        formround: ['d'],
        specialcurve: ['h', 'l'],
        prism2: ['h', 'l', 'l1'],
        prism3: ['h', 'l', 'l1'],
        flat: ['h', 'l'],
        other: []
    };

    var valueForCalc = {
        specialthickness: ['h', 'l'],
        formcurved: ['h', 'l'],
        formcupcircle: ['h', 'l'],
        formhalfmoon: ['h', 'l'],
        formroundedcurved1: ['h', 'l'],
        formroundedcurved2: ['h', 'l'],
        form2sectionscut: ['h', 'l'],
        form1sectioncut: ['h', 'l'],
        formtrapeze: ['h', 'l'],
        formtriangle: ['h', 'l'],
        form1sectionsimple: ['h', 'l'],
        form4sections: ['h', 'l'],
        formoval: ['d', 'd1'],
        formtrianglerect: ['h', 'l'],
        formround: ['d'],
        specialcurve: ['h', 'l'],
        prism2: ['h', 'l', 'l1'],
        prism3: ['h', 'l', 'l1'],
        flat: ['h', 'l']
    };

    var inputThickness = {
        specialthickness: [3, 5],
        formcurved: [4, 3, 5],
        formcupcircle: [4, 3, 5],
        formhalfmoon: [4, 3, 5],
        formroundedcurved1: [4, 3, 5],
        formroundedcurved2: [4, 3, 5],
        form2sectionscut: [4, 3, 5],
        form1sectioncut: [4, 3, 5],
        formtrapeze: [4, 3, 5],
        formtriangle: [4, 3, 5],
        form1sectionsimple: [4, 3, 5],
        form4sections: [4, 3, 5],
        formoval: [4, 3, 5],
        formtrianglerect: [4, 3, 5],
        formround: [4, 3, 5],
        specialcurve: false,
        prism2: false,
        prism3: false,
        other: false
    };

    var inputSpecialLength = {
        prism3: [351, 405, 420, 435, 438, 454, 460, 520, 550, 590]
    };

    /*var inputSpecialAngle = {
     prism2               : [45, 90],
     prism3               : [45]
     };*/

    var prism2MaxLength = 868;

    var prism3MaxLength1 = {
        351: 134,
        405: 212,
        420: 215,
        435: 185,
        438: 197,
        454: 147,
        460: 225,
        520: 222,
        550: 220,
        590: 105
    }

    var prism2MaxLength1 = {
        702: 369,
        714: 401,
        765: 390,
        767: 387,
        780: 480,
        784: 427,
        797: 337,
        863.5: 438.5,
        868: 423
    }

    // H (value) depends of L-min_l1-max_l1 (key format)
    var prism3MaxHeight = {
        '351-50-134': 385,
        '405-50-212': 475,
        '420-50-106': 430,
        '420-106-130': 430,
        '420-130-160': 452,
        '420-160-165': 455,
        '420-165-200': 434,
        '420-200-215': 470,
        '435-50-185': 575,
        '438-50-75': 500,
        '438-75-132': 396,
        '438-132-135': 401,
        '438-135-197': 422,
        '454-50-147': 417,
        '460-50-225': 470,
        '520-50-222': 505,
        '550-50-220': 564,
        '590-50-105': 425
    }

    // H (value) depends of L-min_l1-max_l1 (key format)
    var prism2Limits = [
        [702, 369, 402],
        [714, 401, 465],
        [765, 390, 508],
        [767, 387, 506],
        [780, 480, 520],
        [784, 427, 484],
        [797, 337, 349],
        [863.5, 438.5, 396],
        [868, 423, 410]
    ];

    var calcBase = function (h, l) {
        return (l * h) / 1000000;
    }

    var calcRound = function (d) {
        return (d * d) / 1000000;
    }

    var calcOval = function (d, d1) {
        return (d * d1) / 1000000;
    }

    var calcPrism2 = function (h, l, l1) {
        return ((l + l1) * h) / 1000000;
    }

    var calcPrism3 = function (h, l, l1) {
        return ((l + (l1 * 2)) * h) / 1000000;
    }

    var calcSurface = {
        specialthickness: calcBase,
        formcurved: calcBase,
        formcupcircle: calcBase,
        formhalfmoon: calcBase,
        formroundedcurved1: calcBase,
        formroundedcurved2: calcBase,
        form2sectionscut: calcBase,
        form1sectioncut: calcBase,
        formtrapeze: calcBase,
        formtriangle: calcBase,
        form1sectionsimple: calcBase,
        form4sections: calcBase,
        formoval: calcOval,
        formtrianglerect: calcBase,
        formround: calcRound,
        specialcurve: calcBase,
        prism2: calcPrism2,
        prism3: calcPrism3,
        flat: calcBase
    }

    function getTarif(type) {
        switch (type) {
            case 'prism2':
                if (typeof prism2_tarif === 'undefined') {
                    var prism2_tarif = parseFloat($("#input_prism2_tarif").val());
                }
                return parseFloat(prism2_tarif);

            case 'prism3':
                if (typeof prism3_tarif === 'undefined') {
                    var prism3_tarif = parseFloat($("#input_prism3_tarif").val());
                }
                return parseFloat(prism3_tarif);

            case 'specialcurve':
                if (typeof specialcurve_tarif === 'undefined') {
                    var specialcurve_tarif = parseFloat($("#input_special_curve_tarif").val());
                }
                return parseFloat(specialcurve_tarif);

            case 'thickness3':
                if (typeof std_tarif_3 === 'undefined') {
                    var std_tarif_3 = parseFloat($("#input_std_tarif_3").val());
                }
                return parseFloat(std_tarif_3);
            case 'thickness4':
                if (typeof std_tarif_4 === 'undefined') {
                    var std_tarif_4 = parseFloat($("#input_std_tarif_4").val());
                }
                return parseFloat(std_tarif_4);
            case 'thickness5':
                if (typeof std_tarif_5 === 'undefined') {
                    var std_tarif_5 = parseFloat($("#input_std_tarif_5").val());
                }
                return parseFloat(std_tarif_5);

            case 'autoclean':
                if (typeof auto_tarif === 'undefined') {
                    var auto_tarif = parseFloat($("#input_auto_tarif").val());
                }
                return parseFloat(auto_tarif);

            default:
                return false;
        }
    }

    function razPriceEstimate() {
        price = 0.0;
        $('.estimate-price').html('');
        $('.error').remove();
    }

    function calcTarif(type) {
        tarif = 0.0;
        surface = 0;
        thickness = '';
        glassmodel = $("#glassmodel").val();
        $thickness = $('#epaisseur');
        $estimate_price = $("#active_li").find('.estimate-price');

        t_fields = new Array();

        $estimate_price.html('');

        $('.calc-error').remove();


        $.each(valueForCalc[type], function (k, v) {
            t_fields.push(parseInt($('#' + v).val()));
        });
        if (t_fields.length == 2) {
            t_fields.push('');
        }

        if (type == 'prism2' || type == 'prism3' || type == 'specialcurve') {
            tarif = getTarif(type);
        }
        else {
            thickness = 'thickness' + $thickness.val();
            tarif = getTarif(thickness);
        }

        if (glassmodel == 'autoclean') {
            tarif = getTarif(glassmodel);
        }

        surface = calcSurface[type](t_fields[0], t_fields[1], t_fields[2]);

        price = surface * tarif;

        if (typeof standard_min_tarif === 'undefined') {
            var standard_min_tarif = $("#input_standard_min_tarif").val();
        }

        if (typeof prism3_min_tarif === 'undefined') {
            var prism3_min_tarif = $("#input_prism3_min_tarif").val();
        }

        if (typeof other_min_tarif === 'undefined') {
            var other_min_tarif = $("#input_other_min_tarif").val();
        }

        if (typeof special_supp === 'undefined') {
            var special_supp = $("#input_special_supp").val();
        }

        if (type == 'prism2' || type == 'prism3' || type == 'specialcurve') {
            if (price < prism3_min_tarif) {
                price = prism3_min_tarif;
            }
        }
        else {
            if ($thickness.val() == 4) {
                if (price < standard_min_tarif) {
                    price = standard_min_tarif;
                }
            }
            else {
                if (price < other_min_tarif) {
                    price = other_min_tarif;
                }
            }

            if (type != "flat" || $thickness.val() != 4) {
                price = parseFloat(price) + parseFloat(special_supp);
            }
        }

        if (typeof tax_value === 'undefined') {
            var tax_value = $("#input_tax_value").val();
        }
        if (typeof currencySign === 'undefined') {
            var currencySign = $("#input_currencySign").val();
        }
        if (typeof currencyRate === 'undefined') {
            var currencyRate = $("#input_currencyRate").val();
        }
        if (typeof currencyFormat === 'undefined') {
            var currencyFormat = $("#input_currencyFormat").val();
        }
        if (typeof currencyBlank === 'undefined') {
            var currencyBlank = $("#input_currencyBlank").val();
        }
        if (typeof txt_product_price === 'undefined') {
            var txt_product_price = $("#input_txt_product_price").val();
        }

        priceTTC = price * tax_value * currencyRate;
        priceTTC = formatCurrency(priceTTC, currencyFormat, currencySign, currencyBlank);
        $estimate_price.html('<p>' + txt_product_price + ' <span class="price">' + priceTTC + '</span></p>');
    }

    var glassOrder = {
        specialthickness: true,
        formcurved: true,
        formcupcircle: true,
        formhalfmoon: true,
        formroundedcurved1: true,
        formroundedcurved2: true,
        form2sectionscut: true,
        form1sectioncut: true,
        formtrapeze: true,
        formtriangle: true,
        form1sectionsimple: true,
        form4sections: true,
        formoval: true,
        formtrianglerect: true,
        formround: true,
        specialcurve: false,
        prism2: true,
        prism3: true,
        other: false
    };

    function displayErrorL1(l, maxL1) {
        element = '<div class="l1-error error">' + txt_l1_error_1 + ' ' + l + txt_l1_error_2 + ' ' + maxL1 + 'mm.</div>';
        $('.l1').append(element);
    }

    function displayErrorH(l, l1, maxH) {
        element = '<div class="h-error error">' + txt_h_error_1 + ' ' + l + ' / ' + l1 + txt_h_error_2 + ' ' + maxH + 'mm.</div>';
        $('.h').append(element);
    }

    function getMaxHeight(l, l1, type) {
        if (type == 'prism3') {
            var keys = Object.keys(prism3MaxHeight);
        }
        else {
            if (type == 'prism2') {
                var keys = Object.keys(prism2MaxHeight);
            }
        }

        var l = parseInt(l);
        var l1 = parseInt(l1);

        var retour = null;

        keys.forEach(function (key) {
            var res = key.split('-');
            var k_l = parseInt(res[0]);
            var minL1 = parseInt(res[1]);
            var maxL1 = parseInt(res[2]);

            if (minL1 == 50) {
                if (l == k_l && (l1 >= minL1 && l1 <= maxL1)) {
                    if (type == 'prism3') {
                        retour = (prism3MaxHeight[key]);
                    }
                    else {
                        if (type == 'prism2') {
                            retour = (prism2MaxHeight[key])
                        }
                    }
                }
            }
            else {
                if (l == k_l && (l1 > minL1 && l1 <= maxL1)) {
                    if (type == 'prism3') {
                        retour = (prism3MaxHeight[key]);
                    }
                    else {
                        if (type == 'prism2') {
                            retour = (prism2MaxHeight[key])
                        }
                    }
                }
            }
        });

        return retour;
    }

    // for prism 3
    function activeCheckOnL1(type) {
        var special_length = $('#l2');
        var l1 = $('#l1');

        l1.off('change');

        if (type == 'prism3') {
            l1.on('change', function () {
                var l_v = special_length.val();
                var l1_v = l1.val();
                maxL1 = prism3MaxLength1[l_v];

                $('.l1-error, .h-error').remove();

                if (l1_v < 50 || l1_v > maxL1) {
                    l1.val('');
                    displayErrorL1(l_v, maxL1);
                }
            })
        }
    }

    // for prism 3
    function activeCheckOnH(type) {
        var special_length = $('#l2');
        var l1 = $('#l1');
        var h = $('#h');

        h.off('change');

        if (type = 'prism3') {
            h.on('change', function () {
                var l_v = special_length.val();
                var l1_v = l1.val();
                var h_v = h.val();

                maxH = getMaxHeight(l_v, l1_v, type);
                $('.h-error').remove();

                if (l1_v == '') {
                    $('.h').append('<div class="h-error error">' + txt_before_l1 + '</div>');
                    h.val('');
                }
                else {
                    if ((h_v < 50 || h_v > maxH)) {
                        h.val('');
                        displayErrorH(l_v, l1_v, maxH);
                    }
                }
            });
        }
    }

    Array.prototype.clone = function () {
        var arr = [];
        for (var i = 0; i < this.length; i++) {
            //      if( this[i].constructor == this.constructor ) {
            if (this[i].clone) {
                //recursion
                arr[i] = this[i].clone();
                break;
            }
            arr[i] = this[i];
        }
        return arr;
    }

    function deepCopy(obj) {
        if (Object.prototype.toString.call(obj) === '[object Array]') {
            var out = [], i = 0, len = obj.length;
            for (; i < len; i++) {
                out[i] = arguments.callee(obj[i]);
            }
            return out;
        }
        if (typeof obj === 'object') {
            var out = {}, i;
            for (i in obj) {
                out[i] = arguments.callee(obj[i]);
            }
            return out;
        }
        return obj;
    }

    function activeCheckPrism2(type) {
        $('#l, #l1, #h').off('change');

        var l = $('#l');
        var l1 = $('#l1');
        var h = $('#h');

        if (type == 'prism2') {
            $('#l, #l1, #h').on('change', function () {
                var all_set = true;

                $.each(inputForm[type], function (k, v) {
                    if (!($('#' + v).val())) {
                        all_set = false;
                    }
                });

                if (all_set) {

                    var order = false;
                    var limits = [];
                    limits = deepCopy(prism2Limits);

                    var dims = [
                        l.val(),
                        l1.val(),
                        h.val()
                    ];

                    for (var key = 0; key < limits.length; key++) {
                        if (dims[0] <= limits[key][0]) {
                            if (dims[1] <= limits[key][1]) {
                                if (dims[2] <= limits[key][2]) {
                                    order = true;
                                }
                            }
                        }
                    }
                    //                }

                    $('.prism2-error').remove();

                    if (!order) {
                        $('.h').append('<div class="prism2-error error">' + txt_unavailable_prism2 + ' <a class="show-table-ava" href="">' + txt_table_ava + '</a></div>');
                        l.val('');
                        l1.val('');
                        h.val('');

                        $('.show-table-ava').on('click', showTableAvailabily);
                    }
                }
            });
        }
    }

    function launchCalc(type) {
        activeCheckOnL1(type);
        activeCheckOnH(type);
        activeCheckPrism2(type);

        if (type && type != 'specialform' && type != 'specialprismatic') {

            if (inputForm[type] != undefined) {
                calcTarif(type);
            }
            $('.calculate-price').show();
        }
    }
    // function switchDisplayForm() {
    //     if ($('#no').is(':checked')) {
    //         $('#unknow').show(300);
    //     } else {
    //         $('#unknow').hide(300);
    //     }

    //     if ($('#yes').is(':checked')) {
    //         $('#know').show(300);
    //     } else {
    //         $('#know').hide(300);
    //     }
    // }
})(jQuery);
